<template>
  <div>
    <el-upload
        action="https://jsonplaceholder.typicode.com/posts/"
        list-type="picture"
        :show-file-list="false"
        :on-success='uploadSuccess'
        :on-progress='uploaded'
    >
      <div>上传</div>
    </el-upload>
    <div class="imgList" v-for="(item,index) in urlList" :key="index">
      <div class="box" v-if="item.uid == uid">
        <el-progress type="circle" :percentage="Math.round(item.percentage)"></el-progress>
      </div>
      <img v-if="item.percentage == 100" :src="item.url" alt="">
    </div>
  </div>

</template>

<script>
export default {
  name: "dome",
  data() {
    return {
      urlList:'',  //图片List
      uid:null,    //图片唯一的标识id
    }
  },
  methods: {
    uploadSuccess(response, file, fileList){
      this.uid = 1 //随便一个值,上传成功时,进度条消失
    },
    uploaded(event, file, fileList){
      this.urlList = fileList
      this.uid = file.uid
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
